










import Vue from "vue";

export default Vue.extend({
  name: "ClubDirMain2d",
  data: () => {
    return {};
  },
});
